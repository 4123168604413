import { AppRouteMap } from '@/AppRoutes';
import VHoverMenu from '@/common/component/vcat/VHoverMenu';
import { GnbMenuButton, HeaderWrap } from '@/common/layout/Header.style';
import { VcatColorPalette } from '@/common/styles/VColor';
import GlobalStore from '@/GlobalStore';
import FlagManager from '@/infra/launchdarkly/FlagManager';
import { LDFeatureFlag } from '@/infra/launchdarkly/LDFeatureFlags';
import Utils from '@/infra/Utils';
import UserSwitchModal from '@/pages/modal/UserSwitchModal';
import NiceModal from "@ebay/nice-modal-react";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SwitchAccessShortcutAddOutlined from '@mui/icons-material/SwitchAccessShortcutAddOutlined';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { shallow } from "zustand/shallow";

export interface IHeader {
    label: string;
    to?: string;
    children?: Array<{
        label: string;
        to: string;
        enabled?: () => boolean;
        featureFlag?: LDFeatureFlag;
    }>;
}

export const headersData: IHeader[] = [
    {
        label: 'Template',
        children: [
            { label: '등록', to: '/motion/work', enabled: () => Utils.isAlpha() },
            { label: '템플릿 그룹 관리', to: '/motion/group/manage' },
            { label: 'U2V 관리', to: '/motion/u2v/manage' },
            { label: 'T2V 관리', to: '/motion/t2v/manage' },
            { label: '템플릿 추천 그룹 관리', to: '/motion/group/recommend' },
            // {  label: 'Vcat', to: '/u2v/vcat' },
        ],
    },
    {
        label: 'Media',
        children: [
            { label: '랜더링 결과 관리', to: '/media/result' },
            { label: '사용자별 Custom Font', to: '/asset/custom-font' },
            { label: '갤러리 관리', to: '/media/gallery' },
            { label: '쇼케이스', to: '/media/showcase' },
            { label: 'U2V 이미지 에디터', to: '/media/editor' },
            // {  label: 'Vcat', to: '/u2v/vcat' },
        ],
    },
    // {
    //     label: 'V2V',
    //     children: [
    //         {  label: '등록', to: '/v2v/create' },
    //         {  label: '관리', to: '/v2v/manage' },
    //     ],
    // },
    // {
    //     label: 'T2V',
    //     children: [
    //         {  label: '등록', to: '/t2v/create' },
    //         {  label: '관리', to: '/t2v/manage' },
    //     ],
    // },
    {
        label: 'U2I',
        children: [
            { label: '템플릿 등록', to: '/u2i/create' },
            { label: '템플릿 관리', to: '/u2i/template' },
            { label: '추천 사이즈 관리', to: '/u2i/variation/recommend' },
            { label: '고정형 베리에이션 관리', to: '/u2i/variation/fixed' },
            { label: '렌더링 결과 관리', to: '/u2i/result' },
            { label: '배경제거 이력 관리 ', to: '/u2i/remove-bg-history' },
            // { label: 'CustomSize', to: '/u2i/customSize' },
        ],
    },
    {
        label: 'Resource',
        children: [
            { label: '폰트', to: '/asset/font' },
            { label: '오디오', to: '/asset/audio' },
            { label: '컬러', to: '/asset/color' },
            { label: '인기 문구', to: '/asset/recommend-text' },

        ],
    },
    {
        label: 'Tag',
        to: '/tag/manage',
    },
    {
        label: 'User',
        children: [
            { label: '파트너 관리', to: '/partner/manage' },
            { label: '사용자 관리', to: '/user/manage' },
            { label: '권한 그룹 관리', to: '/role/manage' },
            { label: 'Team 관리', to: '/team/manage' },
            { label: 'Custom 설정 관리', to: '/team/custom-feature/manage' },
            { label: '회사 관리', to: '/user/company/manage' }
        ],
    },
    {
        label: 'Order',
        children: [
            { label: "구독 관리", to: '/subscription' },
            { label: "구독 타입 관리", to: '/subscription/type' },
            { label: '권한 그룹 관리', to: '/worklimit' },
            { label: "구독 타입 설명 관리", to: '/subscription/type/description' },
            { label: "프로모션 코드 관리", to: '/coupon/type' },
            { label: "결제 내역", to: '/payment' },
            // { label: '[legacy] 주문 관리', to: '/order/user' },
        ],
    },
    {
        label: "AI",
        children: [
            { label: "GPT Prompt Manage", to: '/ai/gpt/prompt' },
            { label: "GPT Prompt Results", to: '/ai/gpt/result' },
            { label: "GPT Prompt History", to: "/ai/gpt/history" },
            { label: "Text to Speech", to: '/ai/tts' },
            { label: "Dalle", to: "/ai/dalle" },
            { label: "Dalle History", to: "/ai/dalle/history" },
        ],
    },
    {
        label: "COPYGEN",
        children: [
            { label: "Copygen Api Version", to: "/ai/copygen/api" },
            { label: "GenAi History", to: '/ai/gen/history' },
            { label: "SHOUTER API 리포트", to: '/ai/copygen/report' }
        ],
    },
    {
        label: "IMAGINE",
        children: [
            { label: 'Imagine History', to: '/ai/imagine/history' },
            { label: 'Imagine Issue', to: '/ai/imagine/issue' },
        ]
    },
    {
        label: 'Report',
        children: [
            { label: '영상 제작 결과 리포트', to: '/report/media/result' },
            { label: 'DS URL Scrap 리포트', to: '/report/ds/scrap' },
            { label: '브이캣 서비스 대시보드', to: '/report/service' },
            { label: '이미지 제작 결과 리포트', to: '/report/image/result' },
            { label: '생성 이미지 리포트', to: '/report/imagine/history' },
            { label: '소셜 포스팅 결과 리포트', to: '/report/posting' }
        ],
    },
    {
        label: "Ados",
        children: [
            { label: "Ad workspace", to: '/ados/workspace' },
            { label: "연결된 채널", to: '/ados/channels' },
            { label: "크리에이티브 미디어", to: '/ados/creatives' }
        ],
    },
    {
        label: "ETC",
        children: [
            { label: "Partner Test", to: '/partner/test' },
            { label: "가이드", to: '/guide/manage' },
            { label: "가이드 그룹", to: '/guide/group/manage' },
            { label: "Custom Curation", to: '/directory' },
            { label: "Custom Curation Item", to: '/directory/item' }
        ],
    },
    {
        label: 'Sample',
        children: [
            { label: 'Default Page', to: '/sample/layout/default' },
            { label: 'Row2Grid', to: '/sample/layout/row2grid' },
            { label: 'Sample Modal', to: '/sample/modal' },
            { label: 'Design System', to: '/sample/component' },
            { label: 'New Component', to: '/sample/component/new' },
            // { label: 'Calendar', to: '/sample/sns/calendar' },
            // { label: 'FB', to: '/sample/fb' },
            // { label: 'GoogleDrive', to: '/sample/gdrive' },
            // { label: 'Sample Timeline', to: '/sample/layout/timeline' },
            // { label: "sns Posting Modal", to: '/sample/sns' },
            //"/sample/sns-auth",
            { label: "Stripe", to: '/sample/stripe' },
            { label: "Sns Posting Test", to: "/sample/posting" },
            // { label: "Sns Auth Test", to: "/sample/sns-auth" }
        ],
    },
];

const GnbMenu = ({ item }: { item: IHeader; }) => {
    const [hasRole] = GlobalStore.use(state => [state.hasRole], shallow);
    const location = useLocation();
    let { label, children } = item;
    let isSelected = false;

    children = children!.filter(({ to, enabled, featureFlag }) => {
        if (to === location.pathname) isSelected = true;
        if (!!featureFlag && !FlagManager.isFlagOn(featureFlag)) {
            return false;
        }
        return to && hasRole(AppRouteMap[to]?.roles || []) && (!enabled || enabled());
    });

    if (children.length === 0) return null;

    return (
        <VHoverMenu
            renderMenu={({ onClose }) => (
                <Box
                    sx={{
                        backgroundColor: 'white',
                        color: VcatColorPalette.text,
                        borderRadius: 1.5,
                        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.15)',
                        padding: .5,
                    }}
                >
                    {children?.map(({ label, to }) => (
                        <MenuItem
                            key={label}
                            component={RouterLink}
                            to={to}
                            selected={to === location.pathname}
                            onClick={onClose}
                            sx={{ minWidth: 160, p: 1.5 }}
                        >
                            {label}
                        </MenuItem>
                    ))}
                </Box>
            )}
            renderButton={() =>
                <GnbMenuButton
                    sx={{ backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.1)' : 'transparent' }}
                >
                    {label}
                </GnbMenuButton>
            }
            offsetY={-12}
        />
    );
};

const AvatarMenu = () => {
    const [signOut, hasRole] = GlobalStore.use(state => [state.signOut, state.hasRole], shallow);
    const onSignOut = async () => {
        try {
            signOut();
        } finally {
        }
    };
    const onSwitchUser = () => {
        NiceModal.show(UserSwitchModal, {});
    };

    return (
        <VHoverMenu
            renderMenu={({ onClose }) => (
                <Box
                    sx={{
                        backgroundColor: 'white',
                        color: VcatColorPalette.text,
                        borderRadius: 1.5,
                        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.15)',
                        padding: .5,
                    }}
                >
                    {hasRole(['ROLE_VS_CS', 'ROLE_VS_CREATOR']) &&
                        <MenuItem
                            onClick={() => {
                                onSwitchUser();
                                onClose();
                            }}
                        >
                            <SwitchAccessShortcutAddOutlined fontSize="small" sx={{ mr: 1 }} />
                            SwitchUser
                        </MenuItem>
                    }
                    <MenuItem
                        onClick={() => {
                            onSignOut();
                            onClose();
                        }}
                    >
                        <LogoutOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                        Logout
                    </MenuItem>
                </Box>
            )}
            renderButton={() =>
                <IconButton>
                    <img src="/assets/images/avatar.svg" alt="avatar" />
                </IconButton>
            }
            offsetY={-6}
        >
        </VHoverMenu>
    );
};

export default function Header() {
    const [hasRole] = GlobalStore.use(state => [state.hasRole]);
    const location = useLocation();

    return (
        <HeaderWrap>
            <Toolbar>
                <Link component={RouterLink} to="/">
                    <Box component="img" mr={4} src={Utils.isReal() ? '/assets/images/record_logo.svg' : '/assets/images/record_logo_w.svg'} alt="Record Logo" sx={{ height: 24 }} />
                </Link>
                <Stack direction='row'>
                    {headersData.map(header => {
                        const { label, to, children } = header;
                        if (to && hasRole(AppRouteMap[to].roles)) {
                            const isSelected = to === location.pathname;

                            return (
                                <GnbMenuButton
                                    key={label}
                                    component={RouterLink}
                                    to={to}
                                    sx={{ backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.1)' : 'transparent' }}
                                >
                                    {label}
                                </GnbMenuButton>
                            );
                        }
                        else if (children) {
                            return <GnbMenu key={label} item={header} />;
                        }
                        return null;
                    })}
                </Stack>
                <Box sx={{ flexGrow: 1 }} />
                {['/motion/u2v/dpcut'].indexOf(location.pathname) < 0 && <AvatarMenu />}
            </Toolbar>
        </HeaderWrap>
    );
}
