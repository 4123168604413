import AuthGQLClient from '@/infra/AuthGQLClient';
import { Pagable } from '@/infra/CommonType';
import GqlHelper from '@/infra/GqlHelper';
import RecordApiClient from "@/infra/RecordApiClient";
import { QueryFilter } from '@/infra/Utils';
import OrderService from '@/service/OrderService';
import { PlatfromPartner } from '@/service/PartnerServiceV2';

import { Subscription } from '@/service/SubscriptionService';
import { gql } from "@apollo/client";

export enum UserType {
    SMB, PARTNERS, AGENCY
}

export interface UserUpdateInput {
    id: number;
    type: UserType;
    company: string;
    testerYn: boolean;
    emailVerified: boolean;
    maxSession: number;
    country: string;
    comment: string;
}

export interface UserSearchFilter extends QueryFilter {
    filterPeriodType: PeriodType;
    filterType: FilterType;
    country: CountryType;
    userId?: number;
    name?: string;
    company?: string;
    email?: string;
    startAt: Date;
    endAt: Date;
    platformIds: Array<number>;
    vcatOnly: boolean;
    statusSet: Array<UserStatus>;
    adminCompanyName?: string;
}

export interface Partner {
    id: number;
    code: string;
    name: string;
    storeAppYn: boolean;
}

export interface UserAffiliate {
    id: number;
    action: String;
    affiliateInfo: String;
}

export interface User {
    id: number;
    name: string;
    email: string;
    status: string;
    company: string;
    comment?: string;
    type: UserType;
    country: string;
    lastLogedinAt: number;
    emailVerified: boolean;
    partner: Partner;
    partnerUserUid: string;
    createdAt: number;
    maxSession: number;
    testerYn: boolean;
    affiliate: UserAffiliate;
    // activeOrder: ActiveOrder;
    lockYn: boolean;
    adminCompanyName: string;
    registrationPlatformCode?: string;
    passwordUpdatedAt?: number;
    activeSubscription?: Subscription;
    platformUserStores?: Array<PlatformUserMall>;
}


export interface PlatformUserMall {
    id: number;
    partnerId: number;
    storeUId: string;
    defaultYn: string;
    partner: PlatfromPartner;
    createdAt: Date;
}

export interface BasicUserInfo {
    id: number,
    name: string,
    email: string,
    status: string;
}

export interface AdminCompany {
    id: number,
    name: string;
}

export enum UserStatus {
    register = "register",
    live = "live",
    deleted = "deleted",
    rejected = "rejected",
    inactive = "inactive",
}

export interface AuthUser {
    id: number;
    name: string;
    email: string;
    status: UserStatus;
    company: string;
    contract: string;
    lastLogedinAt: number;
    createdAt: number;
    modifiedAt: number;
    authorities: Array<string>;
}

export const InformationList = [
    '테스트 계정',
    '회사명',
    '회사명(내부용)',
    '이메일 확인',
    '사용자 유형',
    '국가',
    '파트너',
    '적용상품',
    '해지예약여부',
    '다음결제일',
    '최초가입일',
    '마지막 로그인',
    '상태',
    '비고'
];

export enum FilterType {
    userId = 'userId',
    name = 'name',
    email = 'email',
    company = 'company',
    inflowPlatform = "inflowPlatform",
    connectPlatform = "connectPlatform",
    defaultPlatform = "defaultPlatform"
}

export enum PeriodType {
    createdAt = 'createdAt',
    lastLoginAt = 'lastLoginAt',
    platformConnectAt = "platformConnectAt",
}

export enum CountryType {
    all = 'all',
    kr = 'kr',
    jp = 'jp',
    others = 'others'
}

export const FILTER_TYPE_OPTIONS = [
    { value: FilterType.userId, label: 'id' },
    { value: FilterType.name, label: 'name' },
    { value: FilterType.email, label: 'email' },
    { value: FilterType.company, label: 'company' },
    { value: FilterType.inflowPlatform, label: '유입플랫폼' },
    { value: FilterType.connectPlatform, label: '연동플랫폼' },
    { value: FilterType.defaultPlatform, label: '사용플랫폼' }
];

export const PERIOD_TYPE_OPTIONS = [
    { value: PeriodType.createdAt, label: '가입일' },
    { value: PeriodType.platformConnectAt, label: '연동일자' },
    { value: PeriodType.lastLoginAt, label: '마지막 로그인' }
];

export const COUNTRY_OPTIONS = [
    { value: CountryType.all, label: 'All' },
    { value: CountryType.kr, label: 'Korea' },
    { value: CountryType.jp, label: 'Japan' },
    { value: CountryType.others, label: 'Others' },
];

class UserService {
    public static readonly USER_FIELDS = gql`
    fragment userFields on User {
        id 
        name
        email
        status
        company
        type 
        maxSession
        testerYn
        lockYn
        country
        lastLogedinAt
        emailVerified
        partner {
            id
            name
            code
        }
        affiliate {
            action
            affiliateInfo
        }
        adminCompanyName
        partnerUserUid
        createdAt
        comment
        deletedAt
        deleteComment
        registrationPlatformCode
        platformUserStores {
            id
            storeUId
            storeName
            subStoreUId
            subStoreName
            subStoreDomain
            subStoreCurrencyCode
            companyName
            domain
            currencyCode
            countryCode
            category
            defaultYn
            createdAt
            partner {
                id
                organization
            }
        }
        passwordUpdatedAt
        activeSubscription {
            id
            state
            beforeState
            term
            startAt
            endAt
            round
            nextTypeId
            nextTerm
            renewBaseDate
            nextRenewAt
            rootId
            parentId
            trial
            organization {
                id
                name
            }
            subscriptionType {
                id
                state
                name
                displayCode
                groupName
                level
                expose
            }
        }
        utmData {
            referer
            utmSource
            utmContent
            utmMedium
            utmCampaign
        }
    }
    `;

    public static readonly WHOAMI = gql`
    query WhoamiQuery {
        me : whoami {
          id
          name 
          email
          authorities
        }
    }
    `;

    public static readonly GET_USER = gql`
    query userForAdmin($id : Long!) {
        userForAdmin(id : $id) {
            ...userFields
        }        
    }
    ${UserService.USER_FIELDS}
    `;


    public static readonly ALL_USERS = gql`
    query usersForAdmin($filter : UserSearchFilter, $page: Int, $size: Int) {
        usersForAdmin(filter : $filter,page: $page, size: $size) {
            ${GqlHelper.pageableGql(`
            ...userFields
            `)}
        }
        
    }
    ${UserService.USER_FIELDS}
    `;

    public static readonly UPDATE_USER = gql`
    mutation updateUserForAdmin($input: UpdateUserForAdminInput!) {
        updateUserForAdmin(input:$input) {
            ...userFields
        }
      }
      ${UserService.USER_FIELDS}
    `;


    public static readonly DELETE_USER = gql`
    mutation deleteUserForAdmin($userId:Long! , $comment : String) {
        deleteUserForAdmin(userId:$userId , comment :$comment) 
      }
    `;

    public static readonly VERIFY_EMAIL = gql`
    mutation verifyEmailForAdmin($userId:Long!) {
        verifyEmailForAdmin(userId:$userId) 
      }
    `;

    public static readonly SEARCH_USER_BY_EMAIL = gql`
    query searchUserEmail($email:String) {
        list : searchUserEmail(email:$email) {
          id
          name
          email
          status
        }
      }
    `;

    public static readonly SEARCH_USER_BY_ADMIN_COMPANY = gql`
    query searchUserByAdminCompanyForAdmin($companyName:String!) {
        list : searchUserByAdminCompanyForAdmin(companyName:$companyName) {
            id
            name
        }
    }
    `;

    public static readonly get = async (id: number) => {
        return await AuthGQLClient.query<{ userForAdmin: BasicUserInfo; }, { id: number; }>({
            query: UserService.GET_USER,
            variables: {
                id
            }
        });
    };

    public static readonly searchUserByEmail = async (email: string) => {
        return await AuthGQLClient.query<{ list: Array<BasicUserInfo>; }, { email: string; }>({
            query: UserService.SEARCH_USER_BY_EMAIL,
            variables: {
                email: email
            }
        });
    };

    public static readonly searchUserByAdminCompany = async (companyName: string) => {
        return await AuthGQLClient.query<{ list: Array<AdminCompany>; }, { companyName: string; }>({
            query: UserService.SEARCH_USER_BY_ADMIN_COMPANY,
            variables: { companyName }
        });
    };

    public static readonly allUsers = async (page: number = 0, size: number = 20) => {
        return (await AuthGQLClient.query<{ usersForAdmin: Pagable<BasicUserInfo>; }, { page: number; size: number; }>({
            query: UserService.ALL_USERS,
            variables: {
                page: page,
                size: size,
            },
        })).data.usersForAdmin;
    };

    public static readonly whoami = async () => {
        return await AuthGQLClient.query<{ me: AuthUser; }, {}>({
            query: UserService.WHOAMI
        });
    };

    public static readonly page = async (filter: UserSearchFilter, page: number = 0, size: number = 0) => {
        const { limit = 20, offset = 0, ...rest } = filter;
        return (await AuthGQLClient.query<{ usersForAdmin: Pagable<User>; }, { filter: UserSearchFilter, page: number, size: number; }>({
            query: UserService.ALL_USERS,
            variables: {
                filter: filter,
                page: page,
                size: size,
            },
        })).data.usersForAdmin;

    };

    public static readonly signIn = async (email: string, password: string) => {
        return await RecordApiClient.post<AuthUser>('/signin', { email, password }, { params: { "required": "vs_admin,vs_creator" } });
    };

    public static readonly signOut = async () => {
        return await RecordApiClient.delete<AuthUser>('/signout');
    };

    public static readonly deleteUser = async (userId: number, comment: string) => {
        return await AuthGQLClient.mutate<{ deleteUserForAdmin: Boolean; }, { userId: number; comment: string; }>({
            mutation: UserService.DELETE_USER,
            variables: {
                userId,
                comment
            }
        });
    };

    public static readonly verifyEmail = async (userId: number) => {
        return await AuthGQLClient.mutate<{ deleteUserForAdmin: Boolean; }, { userId: number; }>({
            mutation: UserService.VERIFY_EMAIL,
            variables: {
                userId
            }
        });
    };

    public static readonly updateUser = async (user: UserUpdateInput) => {
        return (await AuthGQLClient.mutate<{ updateUserForAdmin: Boolean; }, { input: UserUpdateInput; }>({
            mutation: UserService.UPDATE_USER,
            variables: {
                input: {
                    id: user.id,
                    type: user.type,
                    company: user.company,
                    emailVerified: user.emailVerified,
                    maxSession: user.maxSession,
                    testerYn: user.testerYn,
                    country: user.country,
                    comment: user.comment
                }
            }
        })).data?.updateUserForAdmin;
    };
}

export default UserService;